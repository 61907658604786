<template>
  <div>
    <a-tabs v-model="tabKey" size="large" @change="handleTabChange">
      <a-tab-pane v-for="item in tabs" :key="item.value" :tab="item.label"></a-tab-pane>
      <a-button slot="tabBarExtraContent" type="primary" @click="handleAdd">
        添加{{ tabKey === 1 ? '人员' : '团队' }}
      </a-button>
    </a-tabs>
    <template v-if="tabKey === 1">
      <a-row>
        <a-input-search
          placeholder="搜索人名"
          style="width: 200px; margin-right: 20px"
          v-model.trim="searchParams.operatorName"
          @search="getDataList(true)"
        ></a-input-search>
        <a-select
          v-model="searchParams.isDeleted"
          placeholder="选择状态"
          style="width: 200px"
          @change="getDataList(true)"
          allowClear
        >
          <a-select-option :value="0">正常</a-select-option>
          <a-select-option :value="1">已删除</a-select-option>
        </a-select>
      </a-row>
      <a-row>
        <div style="margin: 15px 0">
          人员数 <span style="font-weight: bold">{{ pagination.total || 0 }}</span>
        </div>
      </a-row>
    </template>
    <template v-if="tabKey === 2">
      <a-row style="margin-bottom: 15px">
        <a-input-search
          placeholder="团队名称"
          style="width: 200px; margin-right: 20px"
          v-model="searchParams.groupName"
          @search="getDataList(true)"
          allowClear
        ></a-input-search>
        <BrandSelectInput
          style="width: 200px"
          placeholder="品牌"
          v-model="searchParams.principalId"
          show-search
          option-filter-prop="children"
          @onChange="getDataList(true)"
          allowClear
        />
      </a-row>
    </template>
    <a-table :columns="columns" row-key="id" :pagination="false" :loading="tableLoading" :data-source="dataList">
      <div slot="operatorCount" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <template v-if="text.xhsOperatorList.length">
              <div v-for="(v, i) in text.xhsOperatorList" :key="i">{{ i + 1 }}、{{ v.operatorName }}</div>
            </template>
          </template>
          <div>{{ text.operatorCount }}</div>
        </a-tooltip>
      </div>
      <div slot="pOperation" slot-scope="text, record">
        <template v-if="record.isDeleted === 0">
          <a-button type="link" @click="handleChangeTeam(record.id)">变更团队</a-button>
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-button type="link" :disabled="!record.memberCount" @click="handleOpenTrans(record)"
                  >转移资源</a-button
                >
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                  placement="topRight"
                  title="确定删除？"
                  ok-text="删除"
                  cancel-text="取消"
                  @confirm="handleDelMember(record.id)"
                >
                  <a-button type="link" style="color: #f00">删除</a-button>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
        <template v-if="record.isDeleted === 1">
          <div style="cursor: not-allowed">已删除</div>
        </template>
      </div>
      <div slot="tOperation" slot-scope="text, record">
        <a-button type="link" @click="handleTeamEdit(record)">编辑基本信息</a-button>
        <a-popconfirm
          placement="topRight"
          title="确定删除？"
          ok-text="删除"
          cancel-text="取消"
          @confirm="handleDelTeam(record.id)"
        >
          <a-button type="link" style="color: #f00; margin-left: 15px">删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['30', '50', '100']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <!-- 成员 -->
    <a-modal
      :maskClosable="false"
      v-model="showMemberModal"
      @close="handleModalClose"
      @cancel="handleModalClose"
      width="500px"
      centered
    >
      <template slot="title">
        <template v-if="modalType === 'addMember'"> 添加人员 </template>
        <template v-if="modalType === 'changeTeam'">
          <span>变更团队</span
          ><span style="margin-left: 5px; font-size: 12px; color: #898989">已选人员的团队将全部替换成新团队</span>
        </template>
      </template>

      <div>
        <template v-if="modalType === 'addMember'">
          <div style="display: flex; justify-content: space-between; margin-bottom: 15px">
            <span>已选： {{ operatorList.length }} 个</span
            ><a-button type="link" size="small" @click="operatorList = []">清空</a-button>
          </div>
          <a-select
            style="width: 100%"
            v-model="operatorList"
            placeholder="请选择用户"
            show-search
            option-filter-prop="children"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in userList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </template>
        <template v-if="modalType === 'changeTeam'">
          <div style="display: flex; justify-content: space-between; margin-bottom: 15px">
            <span>已选： {{ changeTeamList.length }} 个</span
            ><a-button type="link" size="small" @click="changeTeamList = []">清空</a-button>
          </div>
          <a-select
            style="width: 100%"
            v-model="changeTeamList"
            placeholder="请选择团队"
            show-search
            option-filter-prop="children"
            allowClear
            mode="multiple"
          >
            <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
              item.groupName
            }}</a-select-option>
          </a-select>
        </template>
      </div>
      <template #footer>
        <a-button key="cancel" @click="handleModalClose">取消</a-button>
        <template v-if="modalType === 'addMember'">
          <a-button key="ok" type="primary" :disabled="operatorList.length === 0" @click="handleAddNext"
            >下一步</a-button
          >
        </template>
        <template v-if="modalType === 'changeTeam'">
          <a-button
            key="ok"
            type="primary"
            :disabled="changeTeamList.length === 0"
            :loading="confirmLoading"
            @click="handleTeamConfirm"
            >确定</a-button
          >
        </template>
      </template>
    </a-modal>

    <!-- 团队 -->
    <a-modal
      :maskClosable="false"
      v-model="showGroupModal"
      :title="groupEditParams.id ? '编辑' : '添加团队'"
      @close="handleGroupClose"
      @cancel="handleGroupClose"
      width="500px"
      centered
    >
      <a-form-model
        ref="groupForm"
        :model="groupEditParams"
        :rules="groupRules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label="名称" prop="groupName">
          <a-input placeholder="请输入名称" v-model.trim="groupEditParams.groupName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="品牌" prop="principalId">
          <BrandSelectInput
            placeholder="请选择品牌"
            show-search
            option-filter-prop="children"
            v-model="groupEditParams.principalId"
          />
        </a-form-model-item>
        <a-form-model-item label="负责人" prop="operatorUserName">
          <a-select
            v-model="groupEditParams.operatorUserName"
            placeholder="运营人"
            show-search
            option-filter-prop="children"
            allowClear
          >
            <a-select-option v-for="item in userList" :key="item.id" :value="item.username">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <template #footer>
        <a-button key="cancel" @click="handleGroupClose">取消</a-button>
        <a-button key="ok" type="primary" :loading="addLoading" @click="handleGroupConfirm">确定</a-button>
      </template>
    </a-modal>

    <!-- 转移资源 -->
    <a-modal
      :maskClosable="false"
      v-model="showTransModal"
      title="转移资源"
      @close="handleTransClose"
      @cancel="handleTransClose"
      @ok="handleTransConfirm"
      :confirm-loading="transLoading"
      width="500px"
      centered
    >
      <div>
        <span style="margin-right: 75px"><span style="color: #f00; margin-right: 5px;">*</span>转出方</span>
        <a-input style="width: 300px" disabled v-model="transEditParams.operatorName"></a-input>
      </div>
      <div style="margin: 15px 0">
        <span style="margin-right: 76px"><span style="color: #f00; margin-right: 5px;">*</span>接收方</span>
        <a-select
          style="width: 300px"
          option-filter-prop="children"
          v-model="transEditParams.targetOperatorId"
          show-search
        >
          <a-select-option v-for="item in allMemberList" :key="item.id" :value="item.id">{{
            item.operatorName
          }}</a-select-option>
        </a-select>
      </div>
      <div style="display: flex">
        <span style="margin-right: 45px; width: 85px; flex-shrink: 0">要转移的资源</span>
        <div>
          <div>
            <a-checkbox :checked="true" disabled>负责的门店及其账号</a-checkbox>
          </div>
          <div style="display: flex; flex-wrap: wrap; margin-top: 5px;">
            <span style="margin-bottom: 5px" v-for="(item, index) in sourceList" :key="item.id"
              >{{ item.memberName }} <span v-show="index != sourceList.length - 1">、</span>
            </span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { tabs, peopleColumns, teamColumns, groupRules } from './data';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'xhsTeamManage',
  components: { BrandSelectInput },
  data() {
    return {
      groupRules,
      peopleColumns,
      teamColumns,
      tabs,
      tabKey: 1,
      searchParams: {
        isDeleted: 0,
      },
      operatorList: [],
      changeTeamList: [],
      rules: {},
      userList: [],
      userMount: 0,
      pagination: {
        current: 1,
        pageSize: 30,
        total: 0,
      },
      dataList: [],
      tableLoading: false,
      showMemberModal: false,
      modalType: undefined,
      showGroupModal: false,
      groupEditParams: {
        id: undefined,
        groupName: undefined,
        principalId: undefined,
        operatorUserName: undefined,
      },
      addLoading: false,
      showTransModal: false,
      isChangeEdit: false,
      allTeamList: [],
      allMemberList: [],
      memberEditId: undefined,
      confirmLoading: false,
      transEditParams: {
        operatorName: undefined,
        targetOperatorId: undefined,
        sourceOperatorId: undefined,
        xhsMemberIdList: undefined,
      },
      transLoading: false,
      sourceList: [],
    };
  },
  computed: {
    columns() {
      return this.tabKey === 1 ? peopleColumns : teamColumns;
    },
  },
  watch: {},
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getAllTeamList();
      this.getAllUserList();
      this.getDataList();
    },
    async getDataList(init = false) {
      if (init) this.pagination.current = 1;
      let params = {};
      if (this.tabKey === 1) {
        params = {
          isDeleted: this.searchParams.isDeleted,
          operatorName: this.searchParams.operatorName,
          page: this.pagination.current,
          size: this.pagination.pageSize,
        };
      } else {
        params = {
          principalId: this.searchParams.principalId ? this.searchParams.principalId.split(',')[0] : undefined,
          groupName: this.searchParams.groupName,
          page: this.pagination.current,
          size: this.pagination.pageSize,
        };
      }
      const APINAME = this.tabKey === 1 ? 'getTeamMember' : 'getTeamManageList';
      this.tableLoading = true;
      const { code, data, message } = await api[APINAME](params).finally(() => (this.tableLoading = false));
      if (code === 200) {
        this.dataList = data.list;
        this.pagination.total = data.total;
        if (this.tabKey === 1) {
          this.getAllTeamList();
          this.updateAllMemberList();
        }
      } else {
        this.$message.error(message);
      }
    },
    async updateAllMemberList() {
      const { code, data, message } = await api.getTeamMember({ page: 1, size: 999 });
      if (code === 200) {
        this.allMemberList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    handleTabChange() {
      this.dataList = [];
      this.searchParams = {
        isDeleted: 0,
      };
      this.pagination = this.$options.data().pagination;
      this.getDataList(true);
    },
    async getAllUserList() {
      const { code, message, data } = await api.getAllUserList();
      if (code === 200) {
        this.userList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async getAllTeamList() {
      const { code, message, data } = await api.getTeamManageList({ page: 1, size: 999 });
      if (code === 200) {
        this.allTeamList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleAdd() {
      if (this.tabKey === 1) {
        this.showMemberModal = true;
        this.modalType = 'addMember';
      }
      if (this.tabKey === 2) {
        this.showGroupModal = true;
        this.groupEditParams = {
          id: undefined,
          groupName: undefined,
          principalId: undefined,
          masterOperatorName: undefined,
        };
      }
    },
    async handleDelMember(id) {
      const { code, message } = await api.handleDelOperator(id);
      if (code === 200) {
        this.$message.success('删除成功');
        if (this.dataList.length === 1 && this.pagination.current > 1) {
          this.pagination.current -= 1;
        }
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    handleChangeTeam(id) {
      this.memberEditId = id;
      this.showMemberModal = true;
      this.modalType = 'changeTeam';
    },
    async handleDelTeam(id) {
      const { code, message } = await api.handleDelTeam(id);
      if (code === 200) {
        this.$message.success('删除成功');
        if (this.dataList.length === 1 && this.pagination.current > 1) {
          this.pagination.current -= 1;
        }
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    handleTeamEdit(data) {
      this.groupEditParams.id = data.id;
      this.groupEditParams.principalId = `${data.principalId},${data.principalName}`;
      this.groupEditParams.groupName = data.groupName;
      this.groupEditParams.operatorUserName = data.masterOperatorUsername;
      this.showGroupModal = true;
    },
    handleAddNext() {
      this.modalType = 'changeTeam';
      this.isChangeEdit = false;
    },
    handleGroupConfirm() {
      this.$refs.groupForm.validate(async (valid) => {
        if (valid) {
          const masterOperatorName =
            this.userList.find((v) => v.username == this.groupEditParams.operatorUserName)?.name || undefined;
          const params = {
            id: this.groupEditParams.id,
            groupName: this.groupEditParams.groupName,
            principalId: this.groupEditParams.principalId.split(',')[0],
            principalName: this.groupEditParams.principalId.split(',')[1],
            masterOperatorName,
            masterOperatorUsername: this.groupEditParams.operatorUserName,
          };
          console.log(params);
          this.addLoading = true;
          const { code, message } = await api.handleAddTeam(params).finally(() => (this.addLoading = false));
          if (code === 200) {
            this.$message.success('操作成功');
            this.handleGroupClose();
            this.getDataList();
          } else {
            this.$message.error(message);
          }
        }
      });
    },
    handleGroupClose() {
      this.$refs.groupForm.clearValidate();
      this.showGroupModal = false;
      this.groupEditParams = this.$options.data().groupEditParams;
    },
    handleModalClose() {
      this.showMemberModal = false;
      this.operatorList = [];
      this.changeTeamList = [];
      this.memberEditId = undefined;
    },
    async handleTeamConfirm() {
      this.confirmLoading = true;
      let params = {};
      let APINAME = '';
      if (this.memberEditId) {
        APINAME = 'changeOperatorTeam';
        params = { id: this.memberEditId, operatorGroupIdList: this.changeTeamList };
      } else {
        APINAME = 'handleAddOperator';
        let operatorList = [];
        this.operatorList.forEach((ids) => {
          this.userList.forEach((u) => {
            if (ids === u.id) {
              operatorList.push({
                operatorUsername: u.username,
                operatorName: u.name,
              });
            }
          });
        });
        params = { operatorList, operatorGroupIdList: this.changeTeamList };
      }
      console.log('params ->', params);
      const { code, message } = await api[APINAME](params).finally(() => (this.confirmLoading = false));
      if (code === 200) {
        this.$message.success('操作成功');
        this.handleModalClose();
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    handleOpenTrans(data) {
      if (!data.operatorName) return this.$message.error('数据有误');
      this.transEditParams.operatorName = data.operatorName;
      this.transEditParams.sourceOperatorId = data.id;
      this.showTransModal = true;
      this.getSourceList(data.operatorUsername);
    },
    async handleTransConfirm() {
      if (!this.transEditParams.targetOperatorId) return this.$message.info('请选择接收方');
      this.transLoading = true;
      const params = {
        sourceOperatorId: this.transEditParams.sourceOperatorId,
        targetOperatorId: this.transEditParams.targetOperatorId,
        xhsMemberIdList: this.transEditParams.xhsMemberIdList,
      };
      const { code, message } = await api.handleTransferResource(params).finally(() => (this.transLoading = false));
      if (code === 200) {
        this.$message.success('操作成功');
        this.handleTransClose();
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    handleTransClose() {
      this.showTransModal = false;
      this.sourceList = [];
      Object.assign(this.transEditParams, this.$options.data().transEditParams);
    },
    async getSourceList(username) {
      this.transLoading = true;
      const { code, data, message } = await api.getSourceList({ username }).finally(() => (this.transLoading = false));
      if (code === 200) {
        this.sourceList = data;
        this.transEditParams.xhsMemberIdList = data.map((v) => v.id);
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
