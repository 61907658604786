export const tabs = [
  {
    value: 1,
    label: '人员',
  },
  {
    value: 2,
    label: '团队',
  },
];

export const peopleColumns = [
  {
    align: 'center',
    title: '人员',
    dataIndex: 'operatorName',
  },
  {
    align: 'center',
    title: '团队',
    dataIndex: 'groupNameList',
    ellipsis: true,
    customRender(record) {
      return record;
    },
  },
  {
    align: 'center',
    title: '负责成员',
    dataIndex: 'memberCount',
  },
  {
    align: 'center',
    title: '负责媒体号',
    dataIndex: 'accountCount',
  },
  {
    align: 'center',
    title: '操作人',
    dataIndex: 'actor',
  },
  {
    align: 'center',
    title: '操作时间',
    dataIndex: 'atime',
  },
  {
    align: 'center',
    title: '操作',
    scopedSlots: { customRender: 'pOperation' },
  },
];

export const teamColumns = [
  {
    align: 'center',
    title: '团队名称',
    dataIndex: 'groupName',
    // ellipsis: true,
    // customRender(record) {
    //   return record;
    // },
  },
  {
    align: 'center',
    title: '品牌',
    dataIndex: 'principalName',
  },
  {
    align: 'center',
    title: '人员',
    scopedSlots: { customRender: 'operatorCount' },
  },
  {
    align: 'center',
    title: '负责人',
    dataIndex: 'masterOperatorName',
  },
  {
    align: 'center',
    title: '操作时间',
    dataIndex: 'atime',
  },
  {
    align: 'center',
    width: 260,
    title: '操作',
    scopedSlots: { customRender: 'tOperation' },
  },
];

export const groupRules = {
  groupName: [
    { required: true, message: '请输入团队名称', trigger: 'blur' },
    { max: 20, message: '长度不得超过20个字符', trigger: 'blur' },
  ],
  principalId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
  operatorUserName: [{ required: true, message: '请选择负责人', trigger: 'change' }],
};
